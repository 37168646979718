import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { layoutStyles } from '../style/layoutStyles';
import { SearchStyle } from '../style/searchStyle';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits, Pagination, connectStateResults, Stats } from 'react-instantsearch-dom';
import SearchResult from '../components/searchResult';

const algoliaClient = algoliasearch('SJ5OIZLA8J', '0823a4d7d0d722bea86fafdb01e6a9c6');

var searchComplete=false;
var query="";
if (typeof window !== 'undefined') {
  var url_string = window.location.href;
  var url = new URL(url_string);
  query = url.searchParams.get("q");
}

const Results = connectStateResults(
  ({ searchState, searchResults, children }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      searchComplete &&
        <div className="searchError">
        <h2>Nothing matches your search</h2>
        <ul>
          <li>make sure all words are spelled correctly</li>
          <li>try different search terms</li>
          <li>try fewer search terms</li>
        </ul>
      </div>
    )
);

const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => params.query==="")) {
      searchComplete=false;
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      });
    }

    searchComplete=true;
    return algoliaClient.search(requests);
  },
};

export default ( ) => {
  return (
    <Layout>
      <SEO title="Search Results" />
      <div css={layoutStyles} id="content">
        <div className="grid">
          <div id="sectionPage" className="col-1-1">
            <div id="article" className="col-9-12">
              <h1>Search Results</h1>
              <div css={SearchStyle}>
                <InstantSearch searchClient={searchClient} indexName={process.env.GATSBY_SEARCH_PORTSINDEX}  >
                  <SearchBox defaultRefinement={query} />
                  <Stats />
                  <Results>
                  <Hits hitComponent={SearchResult} />
                  </Results>
                  <Pagination />
                </InstantSearch>
              </div>
            </div>
            <div id="aside" className="col-3-12">
            </div>
          </div>
        </div>
        <div className="whitespacer"></div>
      </div>
    </Layout>
  )
}
