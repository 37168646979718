import React from "react"
import { Link } from "gatsby"
var moment = require('moment');

const SearchResult = ({hit}) => {

const highlight = hit["_highlightResult"];
return (
  <article>
    <Link to={hit.uri} ><h2  dangerouslySetInnerHTML={{ __html: hit.title }}></h2></Link>
    {!! highlight.excerpt && <span dangerouslySetInnerHTML={{ __html: highlight.excerpt.value }}></span>}
    {hit.contentType}<br />
    <strong>{moment(hit.date).format('DD MMMM YYYY')}</strong><br/>
  </article>
  )
}
export default SearchResult;